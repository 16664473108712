
<template>
    <div class="ourdesc-page">
        <img class="ourdesc-page-img" src="https://i.gsxcdn.com/2373363763_yxootf3t.jpg">
        <!-- 头图 -->
        <!-- <top-header :banner-center-url="bCUrl" :banner-url="bUrl" /> -->
        <!-- 数字滚动 -->
        <!-- <div v-if="isArray(numberIntro)" class="number-intro">
            <div v-for="(item, index) in numberIntro" :key="index" class="item">
                <div class="number">
                    <countTo v-if="index === 2" :start-val="0" :end-val="item.number" :duration="2000" :decimals="1" />
                    <countTo v-else :start-val="0" :end-val="item.number" :duration="2000" />
                    <span class="unit">{{item.unit}}</span>
                </div>
                <div class="title">{{item.title}}</div>
            </div>
        </div> -->
        <!-- 双师模式 -->
        <!-- <div
            class="double-teacher"
            v-if="isExistObject(doubleTeachers)"
        >
            <div class="titles">
                <div class="ourdesc-module-title">{{doubleTeachers.title}}</div>
                <div class="ourdesc-module-sub-title">{{doubleTeachers.englishTitle}}</div>
            </div>
            <double-teachers :classify="doubleTeachers.classify" ref="tutor" />
        </div> -->
        <!-- <client-only> -->
        <!-- 了解途途 -->
        <!-- <div class="knowus">
				<div class="titles">
					<div class="ourdesc-module-title">{{ knowus.title }}</div>
					<div class="ourdesc-module-sub-title">{{ knowus.englishTitle }}</div>
				</div>
				<know-us ref="knowus" :infos="knowus.classify" />
			</div> -->
        <!-- <div class="learn-methods">
				<div class="titles">
					<div class="ourdesc-module-title">{{ learnMethods.title }}</div>
					<div class="ourdesc-module-sub-title">{{ learnMethods.englishTitle }}</div>
				</div>
				<learn-methods ref="learnMethods" :cards="learnMethods.classify" />
			</div>
			<div class="visual-progress">
				<div class="titles">
					<div class="ourdesc-module-title">{{ visualProgress.title }}</div>
					<div class="ourdesc-module-sub-title">{{ visualProgress.englishTitle }}</div>
				</div>
				<visual-progress ref="visualProgress" :cards="visualProgress.classify" />
			</div> -->
        <!-- 主讲老师 -->
        <!-- <div class="teachers">
                <div class="titles">
                    <div class="ourdesc-module-title">{{teachers.title}}</div>
                    <div class="ourdesc-module-sub-title">{{teachers.englishTitle}}</div>
                </div>
                <TeacherSwiper ref="teachers" :teacher-list="teacherList" />
                <second-teacher ref="secondTeacher" :second="second" />
                <img class="bacImg" src="https://i.gsxcdn.com/2117974064_ggmthrz7.png">
            </div> -->
        <!-- 科技赋能 -->
        <!-- <div v-if="isExistObject(tech)" class="tech">
				<div class="titles">
					<div class="ourdesc-module-title">{{ tech.title }}</div>
					<div class="ourdesc-module-sub-title">{{ tech.englishTitle }}</div>
				</div>
				<technology :tech="tech" ref="tech" />
			</div> -->
        <!-- <div v-if="isExistObject(characteristic)" class="character">
                <div class="titles">
                    <div class="ourdesc-module-title">{{characteristic.title}}</div>
                    <div class="ourdesc-module-sub-title">{{characteristic.englishTitle}}</div>
                </div>
                <charact ref="charact" :charact="characteristic" />
            </div> -->
        <!-- 克瑞斯报名 -->
        <!-- <div v-if="isExistObject(criss)" class="criss" :style="{ backgroundImage: `url(${criss.imgUrl})` }">
                <div class="more comment" @click="toComments()">
                    了解更多 <div class="icon"></div>
                </div>
                <div class="titles">
                    <div class="ourdesc-module-title">{{criss.title}}</div>
                    <div class="ourdesc-module-sub-title">{{criss.englishTitle}}</div>
                </div>
                <criss ref="criss" :classify="criss.classify" />
                <div v-if="isExistObject(criss)" class="more criss" @click="toCriss()">{{criss.buttonText}}</div>
            </div>
            <div class="server-commitment">
                <div class="titles">
                    <div class="ourdesc-module-title">{{serveCommitment.title}}</div>
                    <div class="ourdesc-module-sub-title">{{serveCommitment.englishTitle}}</div>
                </div>
                <serve-commitment ref="serveCommitment" :cards="serveCommitment.classify" />
            </div> -->
        <!-- </client-only> -->
        <!-- </client-only> -->
    </div>
</template>

<script>
import Vue from 'vue';
import {GRAY_SWITCH} from '@base/constants/common';
import {mapState} from 'vuex';
import api from '~/api';
import countTo from 'vue-count-to';
// import KnowUs from '~/components/ourdesc/KnowUs';
import ServeCommitment from '~/components/ourdesc/ServeCommitment';
import SecondTeacher from '~/components/ourdesc/SecondTeacher';
import TeacherSwiper from '@/components/ourdesc/Teachers';
import Technology from '~/components/ourdesc/Technology';

import Charact from '~/components/ourdesc/Charact';
import TopHeader from '~/components/ourdesc/TopHeader';


// import Comment from '~/components/ourdesc/Comment';
// import AboutUs from '~/components/ourdesc/AboutUs';
// import DoubleTeachers from '~/components/ourdesc/DoubleTeachers';
// import TeachingIdeas from '~/components/ourdesc/TeachingIdeas';

// import Criss from '~/components/ourdesc/Criss';
// import MediaNews from '~/components/ourdesc/MediaNews';


import config from '~/environment';

// import LearnMethods from '~/components/ourdesc/LearnMethods';
// import VisualProgress from '~/components/ourdesc/VisualProgress';


import {throttle} from 'throttle-debounce';
// PC首页数据  (hydra平台)
const ApolloConfigId = process.env.PATH_TYPE === 'master' ? '6247067946268331126' : '6244176856755610530';
const APOLLO_URL = `https://hydra-cdn.gsxcdn.com/activity-fe/${ApolloConfigId}.json?${Date.now()}`;
const PC_TEACHERS = 'https://m.gaotu100.com/brands/#/pcTeacherAggIndex';
const PC_COMMENTS = 'https://www.gaotu100.com/slogan';
// 途途课堂PC官网首页曝光
const HOMEPAGE_VIEW = '9098223926339584';
const BUBBLE_SHOW = '8123465623103488';
/* eslint-disable max-lines */

export default {
    components: {
        TopHeader,
        TeacherSwiper,
        countTo,
        Technology,
        Charact,

        SecondTeacher,
        ServeCommitment,
        // KnowUs,
        // Comment,
        // AboutUs,
        // DoubleTeachers,
        // TeachingIdeas,
        // Criss,
        // MediaNews,
        // LearnMethods,
        // VisualProgress,
    },
    data() {
        return {
            apolloData: {},
            tech: {},
            knowus: {},
            learnMethods: {},
            second: {},
            serveCommitment: {},
            visualProgress: {},
            doubleTeachers: {},
            teachingIdeas: {},
            criss: {},
            news: {},
            characteristic: {},
            aboutUs: {},
            numberIntro: [],
            teachers: {},
            comments: {},
            teacherList: [],
            bCUrl: '',
            bUrl: '',
            PcCrissLink: '',
            footerTimer: null,
            // 服务端请求是否出错
            isAxiosError: false,
            // 控制气泡产生  ready-未出现过 pause-出现过未达到60s暂停 stop-出现了60s不再展示 show-正在展示
            bubbleStatus: 'ready',
            // 气泡清除定时器
            bubbleTimer: null,
        };
    },
    computed: {
        ...mapState({
            isShowBubble: state => state.isShowCategoryBubble,
            hasEnterCategory: state => state.hasEnterCategory
        })
    },
    // 跳转M站首页、请求apollo的数据
    async asyncData({app, redirect, req, error}) {
        const userAgent = (process.server ? req.headers['user-agent'] : navigator.userAgent) || '';
        const mobileClient = userAgent.toLowerCase().match(/(iphone|ipod|ipad|android)/);
        if (mobileClient) {
            return redirect(`https:${config.env.mUrl}/`);
        }
        try {
            app.$axios.defaults.timeout = 5000;
            const data = await app.$axios.get(APOLLO_URL);
            let apolloData = {};

            if (data.data) {
                apolloData = data.data;
            }

            return apolloData;
        }
        catch (e) {
            const error = {
                tag: 'axios-page-index',
                requestUrl: APOLLO_URL,
                userAgent,
                errorMessage: e.toString(),
                axiosDefaults: app.$axios.defaults
            };

            console.error(JSON.stringify(error));

            return {
                // 如果返回错误（超时等...）则在客服端重新请求数据
                isAxiosError: true
            };
        }
    },
    mounted() {
        if (GRAY_SWITCH) {
            const html = document.querySelector('html');
            html && (html.style.filter = 'grayscale(100%)');
        }
        this.$habo.haboViewPage(HOMEPAGE_VIEW);
        this.isAxiosError && this.getApolloDataAgain();
        // this.getList();
        this.getDom();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('beforeunload', this.toSollTop);
    },
    beforeDestroy() {
        if (GRAY_SWITCH) {
            const html = document.querySelector('html');
            html && (html.style.filter = 'none');
        }
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('beforeunload', this.toSollTop);
        this.footerTimer && clearTimeout(this.footerTimer);
        this.bubbleTimer && clearTimeout(this.bubbleTimer);
    },
    methods: {
        toSollTop() {
            window.scrollTo(0, 0);
        },
        toCriss() {
            window.open(`${this.PcCrissLink}`, '_blank');
        },
        toComments() {
            window.location.href = `${window.location.origin}/slogan`;
        },
        // 拿到Dom元素
        getDom() {
            this.$nextTick(() => {
                try {
                    // 处理了解途途部分动画
                    // const knowus = document.querySelector('.knowus');
                    // knowus && this.$refs.knowus.knowusAnimation(knowus);
                    // 处理学习有方法部分动画
                    // const learnMethods = document.querySelector('.learn-methods');
                    // learnMethods && this.$refs.learnMethods.learnMethodsAnimation(learnMethods);
                    // 处理学习有方法部分动画
                    // const visualProgress = document.querySelector('.visual-progress');
                    // visualProgress && this.$refs.visualProgress.visualProgressAnimation(visualProgress);
                    // 处理学习有方法部分动画
                    // const secondTeacher = document.querySelector('.teachers');
                    // secondTeacher && this.$refs.secondTeacher.visualProgressAnimation(secondTeacher);
                    // 处理名师辅导部分动画
                    const tutor = document.querySelector('.double-teacher');
                    tutor && this.$refs.tutor.tutorAnimation(tutor);
                    // 处理优选名师动画
                    const teachers = document.querySelector('.teachers');
                    teachers && this.$refs.teachers.teacherAnimation(teachers);
                    teachers && this.$refs.secondTeacher.secondTeacherAnimation(teachers);
                    // 处理科技赋能动画
                    // const techEl = document.querySelector('.tech');
                    // techEl && this.$refs.tech.techAndAnimation(techEl);
                    // 处理教学理念动画
                    // const idea = document.querySelector('.teach-idea');
                    // idea && this.$refs.idea.ideaAnimation(idea);
                    // 处理高途特色动画
                    const charact = document.querySelector('.character');
                    charact && this.$refs.charact.charactAnimation(charact);
                    // 处理用户评价
                    // const comments = document.querySelector('.comments');
                    // comments && this.$refs.comment.commentAnimation(comments);
                    // 处理克瑞斯报名动画
                    const criss = document.querySelector('.criss');
                    criss && this.$refs.criss.crissAnimation(criss);
                    // 处理关于我们动画
                    // const aboutUs = document.querySelector('.about-us');
                    // aboutUs && this.$refs.aboutUs.aboutUsAnimation(aboutUs);

                    // 处理媒体新闻动画
                    // const news = document.querySelector('.news');
                    // news && this.$refs.news.honorAnimation(news);

                    // 处理服务承诺动画
                    const serveCommitment = document.querySelector('.server-commitment');
                    serveCommitment && this.$refs.serveCommitment.serverCommitmentAnimation(serveCommitment);
                    // 处理底部footer动画
                    // const footer = document.querySelector('.section-footer');
                    // this.footerAnimation(footer);
                }
                catch (error) {
                    console.log('处理动画错误', error);
                }
            });
        },
        // 滚动计算
        handleScroll() {
            this.$nextTick(() => {
                const scrollTop
                    = window.pageYOffset
                        || document.documentElement.scrollTop
                        || document.body.scrollTop;
                const categoryButton = document.querySelector('.category-button');
                if (categoryButton) {
                    if (scrollTop >= 480 && !this.hasEnterCategory && this.bubbleStatus !== 'stop') {
                        switch (this.bubbleStatus) {
                            // 状态为ready时，展示气泡，60s后清除气泡展示
                            case 'ready':
                                this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', true);
                                this.bubbleStatus = 'show';
                                this.$habo.haboViewPage(BUBBLE_SHOW, {source: '/'});
                                this.bubbleTimer = setTimeout(() => {
                                    this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', false);
                                    this.bubbleStatus = 'stop';
                                    clearTimeout(this.bubbleTimer);
                                }, 60000);
                                break;
                            // 状态为暂停时，恢复展示
                            case 'pause':
                                this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', true);
                                this.bubbleStatus = 'show';
                                break;
                            // 其他此时状态不做处理
                            default:
                                break;
                        }
                    }
                    else {
                        // 状态为展示时, 关闭弹窗, 状态改为pause
                        if (this.bubbleStatus === 'show') {
                            this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', false);
                            this.bubbleStatus = 'pause';
                        }
                    }
                }
            });
        },
        getList() {
            // this.$axios.$get('http://localhost:1000/teacherApi/famousList/getIndexPageList').then(res => {
            this.$axios.$get(api.famousTeacher.config).then(res => {
                if (res.code === 0) {
                    this.teacherList = res.data;
                }
            });
        },
        getApolloDataAgain() {
            this.$axios
                .$get(APOLLO_URL)
                .then(res => {
                    const {
                        tech = {},
                        doubleTeachers = {},
                        knowus = {},
                        visualProgress = {},
                        learnMethods = {},
                        serveCommitment = {},
                        second = {},
                        teachingIdeas = {},
                        criss = {},
                        news = {},
                        characteristic = {},
                        aboutUs = {},
                        numberIntro = [],
                        teachers = {},
                        comments = {},
                        bCUrl = '',
                        bUrl = '',
                        teacherList = []
                    } = res;
                    this.tech = tech;
                    this.doubleTeachers = doubleTeachers;
                    this.knowus = knowus;
                    this.visualProgress = visualProgress;
                    this.learnMethods = learnMethods;
                    this.serveCommitment = serveCommitment;
                    this.second = second;
                    this.teachingIdeas = teachingIdeas;
                    this.criss = criss;
                    this.news = news;
                    this.characteristic = characteristic;
                    this.aboutUs = aboutUs;
                    this.numberIntro = numberIntro;
                    this.teachers = teachers;
                    this.comments = comments;
                    this.bCUrl = bCUrl;
                    this.bUrl = bUrl;
                    this.teacherList = teacherList;
                });
        },
        isExistObject(payload) {
            return payload && payload.classify && payload.classify.length > 0;
        },
        isArray(payload) {
            return payload && payload.length > 0;
        },
        // 首页底部动画
        footerAnimation(dom) {
            dom.classList.add('animate-active');
            const wrapper = dom.querySelector('.wrapper');
            const contact = wrapper.querySelector('.contact');
            const itemList = contact.querySelectorAll('.col-md');
            const foot = wrapper.querySelector('.base-footer');
            const contactIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                if (visible) {
                    itemList.forEach((item, index) => {
                        item.classList.add('animate-active');
                    });
                }
            });
            contactIo.observe(contact);
            const footerIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && foot.classList.add('animate-active');
            });
            footerIo.observe(foot);
        }
    }
};

</script>
<style lang="less" scoped>
@import "./../assets/css/ourdesc.less";

@keyframes numberup {
	from {
		top: 242px;
	}

	to {
		top: 0;
	}
}

@keyframes titleup {
	from {
		opacity: 0;
		bottom: -114px;
	}

	to {
		opacity: 1;
		bottom: 0;
	}
}

@keyframes backgroundanimate {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.titles {
	position: relative;
	opacity: 0;

	&.title-active {
		animation: .6s titleup linear;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
	}
}

.knowus {
	padding-top: 90px;
}

.learn-methods {
	width: 100%;
	height: 535px;
	margin-top: -50px;
	padding-top: 335px;
	background: url(https://i.gsxcdn.com/1728173387_du96ftia.gif);
	// background-size: 100% 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.visual-progress {
	padding-top: 113px;
}

.more {
	position: relative;
	left: 50%;
	height: 54px;
	border: 1px solid #bdc7d4;
	border-radius: 27px;
	font-size: 18px;
	color: #696e80;
	line-height: 55px;
	text-align: center;
	cursor: pointer;

	&:hover {
		background: #f5242d;
		color: #fff;
		border-color: #f5242d;
		transition: background-color .3s ease;

		.icon {
			background-image: url(//i.gsxcdn.com/728682730_ixopouow.png);
		}
	}

	.icon {
		display: inline-block;
		width: 24px;
		height: 24px;
		background-image: url(//i.gsxcdn.com/741340063_5kvrwlto.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		vertical-align: sub;
	}
}

.ourdesc-page {
	background-color: #fff;
	min-width: 1280px;
	// padding-bottom: 95px;
	min-height: 1200px;
}

.ourdesc-page-img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.number-intro {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: -122px;
	width: 1202px;
	height: 242px;
	background: #fff;
	border-radius: 20px;
	box-shadow: 0 10px 90px 0 #1d2a430d;

	.item {
		margin: auto;
		width: 400px;
		text-align: center;

		.number {
			font-size: 64px;
			font-weight: 700;
			font-family: DINAlternate, DINAlternate-Bold;
			color: #1e212a;
			line-height: 64px;

			.unit {
				font-size: 18px;
				font-weight: 400;
				line-height: 25px;
			}
		}

		.title {
			padding-top: 16px;
			font-size: 20px;
			color: #a5a6aa;
			line-height: 28px;
		}
	}
}

.double-teacher {
	padding-top: 84px;
}

.tech {
	width: 100%;
	padding-top: 110px;
}

.teach-idea {
	// opacity: 0;
	position: relative;
	width: 100%;
	height: 738px;
	padding: 92px 0 114px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	.ourdesc-module-title {
		color: #fff;
	}

	.ourdesc-module-sub-title {
		opacity: .6;
		color: #fff;
	}
}

.news {
	padding: 150px 0 56px;
	width: 100%;
}

.criss {
	width: 100%;
	height: 1072px;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	overflow: hidden;

	.ourdesc-module-title {
		color: #fff;
	}

	.ourdesc-module-sub-title {
		opacity: .6;
		color: #fff;
	}

	.comment {
		opacity: 0;
		margin: 18px 0 280px;
		width: 226px;
		margin-left: -113px;
		line-height: 54px;

		&.animate-active {
			animation: .5s titleup ease-out;
			// animation-delay: .1s;
			// -webkit-animation-delay: .1s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
		}
	}

	.criss {
		opacity: 0;
		width: 320px;
		height: 60px;
		margin: 95px 0 0 -160px;
		color: #6d2300;
		font-size: 24px;
		font-weight: 600;
		line-height: 60px;
		border: none;
		background: linear-gradient(228deg, #ffe180 8%, #ffd246 96%);
		border-radius: 30px;

		&.active {
			animation: .5s titleup ease-out;
			animation-fill-mode: forwards !important;
			-webkit-animation-fill-mode: forwards !important;
		}
	}
}

.teachers {
	position: relative;
	// opacity: 0;
	width: 100%;
	// height: 924px;
	padding-top: 70px;
	background-color: #F9F9FC;
	// background-image: url(https://i.gsxcdn.com/1729351904_p9dnjai7.gif);
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;


	.more {
		opacity: 0;
		width: 266px;
		margin-left: -133px;

		&.animate-active {
			animation: .5s titleup ease-out;
			// animation-delay: .1s;
			// -webkit-animation-delay: .1s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
		}
	}

	.leftIcon {
		position: absolute;
		width: 142px;
		height: 136px;

		background-repeat: no-repeat;
		background-size: cover;
	}

	.rightIcon {
		position: absolute;
		width: 142px;
		height: 136px;
		background-image: url(//i.gsxcdn.com/816902336_31mei9yr.gif);
		background-repeat: no-repeat;
		background-size: cover;
	}

	.bacImg {
		position: absolute;
		//bottom: 40px;
		// right: 66px;
		right: 3%;
		width: 12%;
		max-width: 240px;
		bottom: 2%;
		//height: 12%;
		// height: 240px;
		// border: 1px solid blue;
	}
}

.character {
	opacity: 0;
	// height: 886px;
	height:734px;
	width: 100%;
	background-color: #fff;
	//background-image: url(https://i.gsxcdn.com/1728844132_bcw3e42z.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 150px;

	&.animate-active {
		opacity: 1;
		animation: 1s backgroundanimate ease-in;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
	}
}

.server-commitment {
	padding-top: 150px;
	background-color: #F9F9FC;
}

.about-us {
	padding-top: 100px;
	width: 100%;
	height: 840px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.comments {
	width: 100%;
	height: 1060px;
	padding-top: 112px;
	background-image: url(//i.gsxcdn.com/731502040_k5h01qyj.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}

/deep/ footer {
	background-color: #f5242d;

	/deep/ .section-footer {
		opacity: 0 !important;

		/deep/ .col-md {
			opacity: 0 !important;
		}
	}
}
</style>
